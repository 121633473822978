import {
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
  DecimalField,
  zodDecimal,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import {
  Brand,
  Form,
  MicrobeStandard,
  Species,
  FlowerGrade,
  GmpGacp,
} from "src/models";

const INGREDIENTS = [
  "Cannabidiol",
  "Delta-9-tetrahydrocannabinol",
  "Cochineal red A (E124)",
  "Glucose",
  "Sucrose",
  "Sorbitol",
  "Tartrazine (E102)",
  "Propylene glycol (E1520)",
  "Triacetin (E1518)",
  "Jack Herer Terpenes",
  "Cannabidiol Extract",
  "Cannabidiol Isolate",
  "MCT Oil",
];
const MARKETS = {
  aus: "Australia",
  gbr: "United Kingdom",
  nzl: "New Zealand",
};
const TERPENES = [
  "Caryophyllene",
  "D-Limonene",
  "β-Myrcene",
  "β-Pinene",
  "Linalool",
  "Humulene",
  "Delta-3-Carene",
  "Terpinolene",
  "trans-Ocimene",
  "β-Caryophyllene",
  "Guaiol",
  "α-Bisabolol",
  "α-Cendrene",
  "α-Humulene",
  "Limonene",
  "γ-Elemene",
  "Fenchol",
  "Ocimene",
  "Sabinene",
  "Farnesene",
  "trans-Neolidol",
  "trans-Caryophyllene",
  "Valencene",
  "E-Neridol",
  "Z-Neridiol",
  "Eucalyptol",
];

export const NO_SPECIES = "NO_SPECIES" as const;

const FormSchema = z
  .object({
    appearance: z.string(),
    aroma: z.string(),
    brand: z.string(),
    cbd: zodDecimal().nullable(),
    cbg: zodDecimal().nullable(),
    cbn: zodDecimal().nullable(),
    controlled: z.string().transform((val) => val === "true"),
    cultivator: z.string(),
    flowerGrade: z.string(),
    form: z.string(),
    gmpGacp: z.string(),
    ingredients: z.array(z.string()),
    intendedUse: z.string(),
    irradiationType: z.string(),
    manufacturer: z.string(),
    market: z.string().min(1),
    microbeStandard: z.string(),
    nameInternal: z.string().min(1),
    parentStrain: z.string(),
    registeredName: z.string(),
    sku: z.string(),
    species: z.string().nullable(),
    strain: z.string(),
    terpenes: z.array(z.string()),
    thc: zodDecimal().nullable(),
  })
  .transform((values) => {
    if (values.form === "FLOWER") {
      values.cbd = values.cbd?.times(10) ?? null;
      values.cbg = values.cbg?.times(10) ?? null;
      values.cbn = values.cbn?.times(10) ?? null;
      values.thc = values.thc?.times(10) ?? null;
    }
    return values;
  });
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  defaultValues: FormType;
  disabled?: boolean;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const FormulaForm = ({ defaultValues, disabled, label, onSubmit }: IProps) => {
  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });
  const formType = methods.watch("form");

  let canaboidUnits = "mg/unit";
  if (formType === "FLOWER") {
    canaboidUnits = "%";
  } else if (formType === "OIL") {
    canaboidUnits = "mg/ml";
  }

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit as any)}>
        <CardContent>
          <TextField
            autoComplete="off"
            fullWidth
            label="Internal name"
            name="nameInternal"
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Market"
            name="market"
            options={Object.entries(MARKETS).map(([value, label]) => ({
              label,
              value,
            }))}
            required
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Registered market name"
            name="registeredName"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Brand"
            name="brand"
            options={Object.values(Brand).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Controlled"
            name="controlled"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Form"
            name="form"
            options={Object.values(Form).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Species"
            name="species"
            options={[
              { value: NO_SPECIES, label: "NO SPECIES" },
              ...Object.values(Species).map((value) => ({ value })),
            ]}
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Strain"
            name="strain"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Ingredients"
            multiple
            name="ingredients"
            options={INGREDIENTS.map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Terpenes"
            multiple
            name="terpenes"
            options={TERPENES.map((value) => ({ value }))}
          />
          <DecimalField
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{canaboidUnits}</InputAdornment>
              ),
            }}
            name="thc"
            label="THC"
            fullWidth
          />
          <DecimalField
            min={0}
            max={100}
            autoComplete="off"
            name="cbd"
            label="CBD (%)"
            fullWidth
          />
          <DecimalField
            min={0}
            max={100}
            autoComplete="off"
            name="cbn"
            label="CBN (%)"
            fullWidth
          />
          <DecimalField
            min={0}
            max={100}
            autoComplete="off"
            name="cbg"
            label="CBG (%)"
            fullWidth
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Appearance"
            name="appearance"
          />
          <TextField autoComplete="off" fullWidth label="Aroma" name="aroma" />
          <TextField
            autoComplete="off"
            fullWidth
            label="Cultivator"
            name="cultivator"
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Parent Strain"
            name="parentStrain"
          />
          <TextField autoComplete="off" fullWidth label="SKU" name="sku" />
          {formType === "FLOWER" && (
            <>
              <SelectField
                autoComplete="off"
                fullWidth
                label="Microbial Standard"
                name="microbeStandard"
                options={Object.values(MicrobeStandard).map((value) => ({
                  value,
                }))}
                required
              />
              <SelectField
                autoComplete="off"
                fullWidth
                label="Flower Grade"
                name="flowerGrade"
                options={Object.values(FlowerGrade).map((value) => ({ value }))}
                required
              />
            </>
          )}
          <TextField
            autoComplete="off"
            fullWidth
            label="Intended Use"
            name="intendedUse"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="GMP/GACP"
            name="gmpGacp"
            options={Object.values(GmpGacp).map((value) => ({ value }))}
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Manufacturer"
            name="manufacturer"
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Irradiation Type"
            name="irradiationType"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton disabled={disabled} label={label} />
          <FormLinks links={[{ label: "Back", to: "/formulas/" }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default FormulaForm;
