import { Title } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useEndpointRolesQuery } from "src/queries/utils";

const HelpEndpointRoles = () => {
  const { data: endpointRoles } = useEndpointRolesQuery();

  return (
    <>
      <Title title="Endpoint accessibility by Role" />
      <Card>
        {endpointRoles ? (
          <TableContainer sx={{ maxHeight: "80vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Endpoint</TableCell>
                  {endpointRoles.allRoles.map((role) => (
                    <TableCell key={role} align="center">
                      {role}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {endpointRoles.endpointRoles.map(
                  ({ endpoint, accessibleTo }) => (
                    <TableRow key={endpoint}>
                      <TableCell>{endpoint}</TableCell>
                      {endpointRoles.allRoles.map((role) => (
                        <TableCell
                          key={role}
                          sx={{
                            backgroundColor: accessibleTo.includes(role)
                              ? "info.main"
                              : undefined,
                            textAlign: "center",
                          }}
                        >
                          {accessibleTo.includes(role) ? "✓" : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Skeleton />
        )}
      </Card>
    </>
  );
};

export default HelpEndpointRoles;
