import { z } from "zod";

export enum ActivityType {
  FORMULA_CREATED = "FORMULA_CREATED",
  FORMULA_UPDATED = "FORMULA_UPDATED",
  STAFF_CREATED = "STAFF_CREATED",
  STAFF_DEACTIVATED = "STAFF_DEACTIVATED",
  STAFF_DISABLED = "STAFF_DISABLED",
  STAFF_ENABLED = "STAFF_ENABLED",
  STAFF_UPDATED = "STAFF_UPDATED",
}

const activitySchema = z.object({
  activity: z.nativeEnum(ActivityType),
  data: z.any(),
  staffId: z.number().nullable(),
  staffMemberEmail: z.string().nullable(),
  timestamp: z.coerce.date(),
});

export type Activity = z.infer<typeof activitySchema>;

export const newActivity = (data: unknown): Activity =>
  activitySchema.parse(data);
