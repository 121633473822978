import { Value } from "@curaleaf-international/components";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Decimal } from "decimal.js";

import RequiresRoles from "src/components/RequiresRoles";
import { Form, Formula, StaffRole } from "src/models";

interface IValueProps {
  form?: Form;
  value?: Decimal | null;
}

const CannaboidValue = ({ form, value }: IValueProps) => {
  if (value === null) {
    return <></>;
  } else {
    switch (form) {
      case Form.FLOWER:
        return <Value percentage={value?.dividedBy(1000)} />;
      case Form.OIL:
        return (
          <>
            <Value text={value?.toString()} /> mg/ml
          </>
        );
      case null:
        return <></>;
      default:
        return (
          <>
            <Value text={value?.toString()} /> mg/unit
          </>
        );
    }
  }
};

interface IProps {
  formula?: Formula;
}

const Details = ({ formula }: IProps) => (
  <Grid container spacing={2}>
    <Grid size={{ xs: 12, sm: 6 }}>
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>
                <Value text={formula?.state} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Registered Market Name</TableCell>
              <TableCell>
                <Value text={formula?.registeredName} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Brand</TableCell>
              <TableCell>
                <Value text={formula?.brand} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Form</TableCell>
              <TableCell>
                <Value text={formula?.form} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Controlled</TableCell>
              <TableCell>
                <Value bool={formula?.controlled} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Species</TableCell>
              <TableCell>
                <Value text={formula?.species ?? "NO SPECIES"} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Strain</TableCell>
              <TableCell>
                <Value text={formula?.strain} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Terpenes</TableCell>
              <TableCell>
                <Value text={formula?.terpenes.join(", ")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>THC</TableCell>
              <TableCell>
                <CannaboidValue form={formula?.form} value={formula?.thc} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CBD</TableCell>
              <TableCell>
                <CannaboidValue form={formula?.form} value={formula?.cbd} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CBG</TableCell>
              <TableCell>
                <CannaboidValue form={formula?.form} value={formula?.cbg} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CBN</TableCell>
              <TableCell>
                <CannaboidValue form={formula?.form} value={formula?.cbn} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Appearance</TableCell>
              <TableCell>
                <Value text={formula?.appearance} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Aroma</TableCell>
              <TableCell>
                <Value text={formula?.aroma} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cultivator</TableCell>
              <TableCell>
                <Value text={formula?.cultivator} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Parent Strain</TableCell>
              <TableCell>
                <Value text={formula?.parentStrain} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SKU</TableCell>
              <TableCell>
                <Value text={formula?.sku} />
              </TableCell>
            </TableRow>
            {formula?.form === "FLOWER" && (
              <>
                <TableRow>
                  <TableCell>Microbial Standard</TableCell>
                  <TableCell>
                    <Value text={formula?.microbeStandard} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Flower Grade</TableCell>
                  <TableCell>
                    <Value text={formula?.flowerGrade} />
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell>Intended Use</TableCell>
              <TableCell>
                <Value text={formula?.intendedUse} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GMP/GACP</TableCell>
              <TableCell>
                <Value text={formula?.gmpGacp} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Manufacturer</TableCell>
              <TableCell>
                <Value text={formula?.manufacturer} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Irradiation Type</TableCell>
              <TableCell>
                <Value text={formula?.irradiationType} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ingredients</TableCell>
              <TableCell>
                <Value text={formula?.ingredients.join(", ")} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </Grid>
    <Grid size={{ xs: 12, sm: 6 }}>
      <RequiresRoles
        roles={[StaffRole.DEVELOPER, StaffRole.DATA_MASTER, StaffRole.QUALITY]}
      >
        <Card>
          {formula !== undefined ? (
            <img
              alt="QR Code to PIL"
              width="100%"
              src={`/v1/formulas/${formula.id}/qr-code/${formula.market}/?format=png`}
            />
          ) : (
            <Skeleton height={200} width={200} />
          )}
          <CardActions>
            <Button
              component="a"
              disabled={formula === undefined}
              download
              href={`/v1/formulas/${formula?.id}/qr-code/${formula?.market}/?format=svg`}
              startIcon={<DownloadIcon />}
            >
              SVG
            </Button>
            <Button
              component="a"
              disabled={formula === undefined}
              download
              href={`/v1/formulas/${formula?.id}/qr-code/${formula?.market}/?format=png`}
              startIcon={<DownloadIcon />}
            >
              PNG
            </Button>
            <Button
              component="a"
              disabled={formula === undefined}
              href={`/v1/formula-histories/${formula?.historyId}/pil/${formula?.market}/`}
            >
              View PIL
            </Button>
          </CardActions>
        </Card>
      </RequiresRoles>
    </Grid>
  </Grid>
);

export default Details;
