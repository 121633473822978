import { z } from "zod";

import { FormulaState } from "src/models/formula";

const formulaStateHistorySchema = z.object({
  id: z.number().int().positive(),
  formulaId: z.string().uuid(),
  reason: z.string().nullable(),
  staffEmail: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  state: z.nativeEnum(FormulaState),
  timestamp: z.coerce.date(),
});

export type FormulaStateHistory = z.infer<typeof formulaStateHistorySchema>;

export const newFormulaStateHistory = (data: unknown): FormulaStateHistory =>
  formulaStateHistorySchema.parse(data);
