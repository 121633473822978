import AccountCircle from "@mui/icons-material/AccountCircle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { MouseEvent, useContext, useState } from "react";

import { AuthContext } from "src/AuthContext";
import { useMutation } from "src/query";

const COMMIT_HASH =
  document
    .querySelector("meta[data-commit-hash]")
    ?.getAttribute("data-commit-hash") ?? "unknown";

const AccountMenu = () => {
  const { member, unauthenticate } = useContext(AuthContext);
  const { mutate: logout } = useMutation({
    mutationFn: async () => await axios.delete("/v1/staff-sessions/"),
    onSuccess: () => unauthenticate(),
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onMenuOpen = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const onMenuClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton color="inherit" onClick={onMenuOpen}>
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        keepMounted
        onClose={onMenuClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem disabled>
          <ListItemText>{member?.email}</ListItemText>
        </MenuItem>
        <MenuItem disabled>
          <ListItemText>Build: {COMMIT_HASH}</ListItemText>
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={() => {
            logout();
            onMenuClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
