import axios from "axios";

import { FormulaStateHistory, newFormulaStateHistory } from "src/models";
import { useQuery } from "src/query";

export const useFormulaStateHistoryQuery = (formulaId: string) => {
  return useQuery<FormulaStateHistory[]>({
    queryKey: ["formulas", formulaId, "history"],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/formula-history/${formulaId}/`, {
        signal,
      });
      return response.data.history.map((data: unknown) =>
        newFormulaStateHistory(data),
      );
    },
  });
};
