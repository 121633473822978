import { useDebounce } from "@curaleaf-international/components";
import { useState } from "react";

export type SortDirection = "asc" | "desc";

export interface PaginationSettings<RowType> {
  pageNumber: number;
  pageSize?: number;
  sortColumn: undefined | keyof RowType;
  sortDirection: SortDirection;
  rawSearchQuery: string;
  searchQuery: string;
}

export interface PaginatedResult<RowType> {
  rows: RowType[];
  totalRecordCount: number;
  paginationSettings: PaginationSettings<RowType>;
}

export const usePaginationSettings = <RowType>(
  defaultConfig: Partial<PaginationSettings<RowType>>,
) => {
  const extendedDefaultConfig: PaginationSettings<RowType> = {
    ...{
      pageNumber: 0,
      sortDirection: "asc",
      rawSearchQuery: "",
      searchQuery: "",
      sortColumn: undefined,
    },
    ...defaultConfig,
  };

  const [paginationSettings, setPaginationSettings] = useState(
    extendedDefaultConfig,
  );
  const debounced = useDebounce(paginationSettings.rawSearchQuery);

  const setPageNumber = (pageNumber: number) => {
    setPaginationSettings({ ...paginationSettings, pageNumber });
  };

  const setPageSize = (pageSize: number) => {
    setPaginationSettings({ ...paginationSettings, pageSize, pageNumber: 0 });
  };

  const toggleSort = (sortColumn: keyof RowType) => {
    if (
      paginationSettings.sortColumn === sortColumn &&
      paginationSettings.sortDirection === "asc"
    ) {
      setPaginationSettings({
        ...paginationSettings,
        sortColumn,
        sortDirection: "desc",
      });
    } else {
      setPaginationSettings({
        ...paginationSettings,
        sortColumn,
        sortDirection: "asc",
      });
    }
  };

  const setSearchQuery = (rawSearchQuery: string) => {
    if (rawSearchQuery !== paginationSettings.rawSearchQuery) {
      setPaginationSettings({
        ...paginationSettings,
        rawSearchQuery,
        searchQuery: rawSearchQuery,
        pageNumber: 0,
      });
    }
  };

  return [
    { ...paginationSettings, searchQuery: debounced },
    { setPageNumber, setPageSize, toggleSort, setSearchQuery },
  ] as const;
};
