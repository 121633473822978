import {
  Search,
  Title,
  useDebounce,
  useHash,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { ChangeEvent, useState } from "react";

import type { States } from "src/pages/Staff/StaffTable";
import StaffTable from "src/pages/Staff/StaffTable";

const Staff = () => {
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const [tab, setTab] = useHash<States>("enabled");

  return (
    <>
      <Title title="Staff" />
      <TabContext value={tab}>
        <TabList
          onChange={(_, value: string) => setTab(value as States)}
          sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}
        >
          <Tab label="Enabled" value="enabled" />
          <Tab label="Disabled" value="disabled" />
        </TabList>
        <Card>
          <Divider />
          <CardContent>
            <Search
              fullWidth
              label="Search"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setRawSearch(event.target.value)
              }
              value={rawSearch}
            />
          </CardContent>
          <StaffTable search={search} state={tab} />
        </Card>
      </TabContext>
    </>
  );
};

export default Staff;
